@import "./ripple";

.btn{
  border-radius: 0.175rem;
}

.btn-ripple {
  @extend .ripple;
}

.btn.s--btn {
  box-sizing: border-box;
  padding-left: 2.2em;
  padding-right: 2.2em;
  border-radius: 3px;
 // margin-bottom: 25px;
  margin-right: 10px;
}

.btn-secondary.s--btn-secondary {
  border: 1px solid #F0F3F5;
  background-color: #F0F3F5;
}

.btn.disabled, .btn:disabled {
  cursor: default;
  background-color: theme-color('gray-100') !important;
  color: theme-color('gray-400') !important;
  border-color: theme-color('gray-300') !important;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.btn-toolbar {
  .btn:not:first-child {
    margin-left: 8px;
  }
}

.btn-pill{
  border-radius:50em ;
}
